// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/cart/ShoppingCartContents.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/cart/ShoppingCartContents.tsx");
  import.meta.hot.lastModified = "1742995935000";
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import ProgressBar from '~/components/products/ProgressBar';
import React, { useEffect, useState } from 'react';
import { addItemToOrder } from '~/providers/external/orders/order';
import { getPoolAmount } from '~/providers/external/pooling/pool';
import { BiosonahAmountAdjuster } from './advanced/BiosonahAmountAdjuser';
export function ShoppingCartContents({
  customer,
  orderLines,
  // From Vendure
  orders,
  // From Biosonah
  refreshOrders,
  currencyCode,
  editable = true,
  adjustOrderLine,
  removeItem
}) {
  _s();
  const isEditable = editable !== false;
  const [poolAmounts, setPoolAmounts] = useState({});
  const [filledAmounts, setFilledAmounts] = useState({});
  const [restIsTaken, setRestIsTaken] = useState({});
  const [amounts, setAmounts] = useState({});
  const amount = async productId => {
    try {
      const response = await getPoolAmount(productId);
      if (response && response.code === 200) {
        return response.items;
      } else return {};
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  useEffect(() => {
    const fetchAmounts = async () => {
      if (orders) {
        const amountsData = {};
        for (const order of orders) {
          const productId = order.product.productId;
          amountsData[productId] = await amount(productId);
        }
        setAmounts(amountsData);
      }
    };
    fetchAmounts();
  }, [orders]);
  useEffect(() => {
    if (orders && amounts) {
      const fAmounts = {};
      const pAmounts = {};
      const rAmounts = {};
      for (const order of orders) {
        const productId = order.product.productId;
        const productAmounts = amounts[productId] || {};
        pAmounts[productId] = productAmounts['amountInPool'] || 0;
        fAmounts[productId] = productAmounts['filledPools'] || 0;
        rAmounts[productId] = productAmounts['restIsTaken'] || false;
      }
      setPoolAmounts(pAmounts);
      setFilledAmounts(fAmounts);
      setRestIsTaken(rAmounts);
    }
  }, [orders, amounts]);
  const assetsUrl = window.ENV.VENDURE_ASSET_URL;
  if (!orders) return <></>;
  return <div className="flow-root">
      <ul role="list" className="-my-6 divide-y divide-gray-200">
        {(orders ?? []).map(order => {
        /*
        const line = orderLines.find(
          (l) => l.id === String(order.vendureOrderLineId),
        );
        if (!line) return <li key={order.id} className="py-6 flex"></li>;
        */

        const poolAmount = poolAmounts[order.product.productId] || 0;
        const filledAmount = filledAmounts[order.product.productId] || 0;
        const restTaken = restIsTaken[order.product.productId] || false;
        return <li key={order.id} className="py-6 flex">
              <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                <img src={assetsUrl + '/' + order.product.preview + '?preset=thumb'} alt={order.product.name} className="w-full h-full object-center object-cover" />
              </div>
              <div className="ml-4 flex-1 flex flex-col">
                <div>
                  <div className="flex justify-between text-base font-bold text-gray-900 ">
                    <h3>
                      <Link to={`/products/${order.product.slug}`}>
                        {order.product.name}
                      </Link>
                    </h3>
                    <p className="ml-4">
                      {currencyCode}{' '}
                      {Number(order.priceWithTax).toLocaleString('de-CH', {
                    maximumFractionDigits: 2
                  })}{' '}
                    </p>
                  </div>
                </div>

                <div className="flex flex-row items-center justify-between w-full">
                  <p className="flex items-center text-sm">
                    Bio Knospe Schweiz
                  </p>
                  <p className="flex items-center text-xs">
                    inkl. {order.tax}% MwSt.
                  </p>
                </div>

                <label htmlFor={`quantity-${order.id}`} className="mr-2 cartContent-text">
                  Menge in {order.unit || 'N/A'}
                </label>

                {/*
                 <AmountAdjuster
                      qtyInCart={order.quantity}
                      baseUnitOfMeasure={order.unit || 'N/A'}
                      increaseBy={order.product.IncreaseBy || 1}
                      minOrderValue={order.product.minOrderValue || 1}
                      inCart={true}
                      addToCart={() => {}}
                      adjustInCart={async (amount: number) => {
                        const actualAmount =
                          order.unit === 'KG' ? amount / 1000 : amount;
                        adjustOrderLine &&
                          adjustOrderLine(order.vendureOrderLineId, amount);
                        await addItemToOrder(
                          order.customerId,
                          order.id,
                          order.vendureOrderLineId,
                          String(order.productVariantId),
                          actualAmount,
                          order.unit,
                        );
                        refreshOrders();
                      }}
                      deleteFromCart={async () => {
                        removeItem && removeItem(order.vendureOrderLineId);
                        await addItemToOrder(
                          order.customerId,
                          order.id,
                          order.vendureOrderLineId,
                          String(order.productVariantId),
                          0,
                          order.unit,
                        );
                        refreshOrders();
                      }}
                      cssPostFix="-inCart"
                      showUnit={false}
                    />
                 */}

                <div className="flex-1 flex items-center text-sm mb-2">
                  {editable ? <BiosonahAmountAdjuster customer={customer} product={{
                variantId: order.productVariantId,
                unit: order.unit,
                increaseBy: order.product.increaseBy
              }} order={order} refreshOrders={refreshOrders} size="small" /> : <div className="text-gray-800">
                      <span className="mr-1">Bestellmenge:</span>
                      <span className="font-medium">
                        {order.quantity} {order.unit}
                      </span>
                    </div>}

                  <div className="flex-1"></div>
                  <div className="flex">
                    {isEditable && <button type="submit" name="removeItem" value={order.vendureOrderLineId} className="font-medium text-primary-600 hover:text-primary-500" onClick={async () => {
                  // removeItem && removeItem(order.vendureOrderLineId);
                  await addItemToOrder(order.customerId, order.id, order.vendureOrderLineId, String(order.productVariantId), 0, order.unit);
                  refreshOrders();
                }}>
                        Löschen
                      </button>}
                  </div>
                </div>
                <div id="product-catalog-progressbar-button-container w-full">
                  <ProgressBar amountInPool={poolAmount} filledPools={filledAmount} color={'custom-progress-bar'} maxValue={order.product.minOrderValue || 100} isSingleOrderPossible={order.product.isSingleOrderPossible} currentValue={order.quantity} infoTextClassname={'product-catalog-progressbar-text w-full'} baseUnit={order.unit} takeRest={restTaken} />
                </div>
              </div>
            </li>;
      })}
      </ul>
    </div>;
}
_s(ShoppingCartContents, "xPBq6bq9WJD23ZYYH8yOjc6dq5o=");
_c = ShoppingCartContents;
var _c;
$RefreshReg$(_c, "ShoppingCartContents");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;